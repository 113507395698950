const SELECTORS = {
    SIDEBAR: ".sidebar",
    SIDEBARNAV: ".sidebar-nav",
    NAVGROUP: ".nav-group",
    NAVSUBGROUP: ".nav-subgroup",
    NAVLEAF: ".nav-leaf",
    ACTIVE: ".active"
};

export default SELECTORS;
